export default {
    title: 'Себет',
    toCheckout: 'Ресімдеу',
    product: 'тауар',
    products: 'тауар',
    product_n: 'тауарды',

    cartInfo: '• 50 000 ₸ басталатын тапсырысты тегін жеткізу <br>' +
        '• Қазпошта, EMEX және DHL қызметтерімен Қазақстан Республикасы бойынша жеткізу',

    timeOut: {
        title: 'Сессия уақыты',
        title2: 'таусылды',
        subTitle: 'Бұл нені білдіреді?',
        text: 'Онлайн-қауіпсіздікті қамтамасыз ету және жеке деректерді қорғау мақсатында браузер сессиясы 20 минут белсенділік болмағаннан кейін тоқтатылады. Бірақ саутта уақыт босқа өткен жоқ. Біз барлық таңдалған тауарларды себетте сақтадық.',
        button: 'Себетке оралу'
    },

    popup: {
        here: 'мұнда',
        forLink: 'сілтемеге',
        delivery: {
            title: 'Жеткізу',
            rules: {
                title: 'Жеткізу шарттары қандай?',
                text_01: 'Біз тауарларды Қазақстан Республикасының барлық аумағы бойынша жөнелтеміз.',
                text_02: 'Біз 50 000 теңге және одан жоғары сомадағы кез келген тапсырысты Қазақстан Республикасы бойынша тегін жеткіземіз.',
                text_03: 'Басқа тапсырыстарды жеткізу құны сайтта тапсырысты ресімдеу кезеңінде есептеледі және жеткізудің таңдалған тәсілі мен өңіріне байланысты.',
                text_04: 'Жөнелту тапсырысты ресімдеген күні немесе көп дегенде келесі күні жүзеге асырылады.',
            },
            dpdW: {
                title: 'DPD бөлімшесіне жеткізу',
                text_01: 'Жеткізу Алматы қаласы бойынша 1-2 күн ішінде және Қазақстан Республикасының басқа елді мекендеріне өңіріне қарай 2 және 8 күн аралығында жүзеге асырылады.',
                text_02: 'DPD бөлімшеде сәлемдеменің сақталу күні – 14 күн.',
            },
            dpdC: {
                title: 'DPD КУРЬЕРІМЕН МЕКЕН-ЖАЙҒА ДЕЙІН ЖЕТКІЗУ',
                text_01: 'Курьердің жеткізуі Алматы қаласы бойынша 2 күн ішінде және Қазақстан Республикасының басқа елді мекендеріне өңіріне қарай 2 және 8 күн аралығында жүзеге асырылады.',
            },
            kazpostW: {
                title: 'Қазпошта бөлімшесіне жеткізу',
                text_01: 'Қазпошта жеткізуі өңірге қарай 2 және 10 күн аралығында жүзеге асырылады (Алматы қ. бойынша жеткізу – 2 күн ішінде).',
                text_02: 'Сәлемдемені бөлімшеде сақтау мерзімі – 7 күн.',
            },
            dhlC: {
                title: 'DHL КУРЬЕРІМЕН МЕКЕН-ЖАЙҒА ДЕЙІН ЖЕТКІЗУ',
                text_01: 'Жеткізу Алматы қаласы бойынша 1-2 күн ішінде және өңірге байланысты Қазақстан Республикасының басқа елді мекендеріне 2-ден 5 күнге дейін жүзеге асырылады.',
            },
            emex: {
                title: 'EMEX курьерлік жеткізу',
                text_01: 'Жеткізу Алматы қаласында 2 күн ішінде және өңірге тәуелді Қазақстан Республикасының өзге елді мекендеріне 2 күннен 11 күнге дейінгі аралықта іске асырылады.',
            },
            summary: 'Өзіңнің елді мекеніңе жеткізу құны бойынша егжей-тегжейлі ақпарат алу үшін, +7 708 914 05 58  нөмірі арқылы жедел желіге қоңырау шал немесе сайттағы online чатқа жаз.',
        },
        return: {
            title: 'Қайтару',
            text_01: 'Қандай да бір себеппен сай келмеген тауарды, оның тауарлық түрін, қаптамасын, этикеткасын, тауардың жүкқұжатын және чекті сақтаған жағдайда <b>сатып алған сәттен бастап 60 күн ішінде қайтара аласың.</b>',
            text_02: 'Тауарды алғаннан кейін жайлы жағдайда, этикеткасын жұлмай киіп көруге және мұқият тексеруге кеңес береміз.',
            text_03: 'Қайтару ережелерімен арқылы таныса аласың',
            text_04: 'Назар аудар: Қазақстан Республикасының заңнамасына сәйкес қайтаруға және айырбастауға жатпайтын тауарлар тобы бар. Мұндай тауарлар тізімімен мынау бойынша таныса аласың',
        },
        contacts: {
            title: 'Байланыс деректері',
            supportTitle: 'Қолдау қызметі',
            supportSchedule: 'Жұмыс уақыты: аптасына 7 күн сағат 10:00-ден 19:00 дейін, демалыссыз:',
            supportB1: '+7 708 914 05 58 нөмірі бойынша',
            supportB1_2: '+7 747 362 21 77 нөмірі бойынша',
            supportB2: 'adidas.kz сайтында онлайн чатта. Чаттың белгішесі сайттың әрбір бетінде тұр.',
            supportB3: 'Хабарламаны чаттың диалог терезесі арқылы жіберуге немесе өзіңе ыңғайлы мессенджерді (Telegram немесе Viber) таңдауға болады.',
            supportCancel: 'Тапсырысты доғару үшін бас',
            chapter_01: {
                title: 'Дүкендердің мекенжайлары мен байланыс деректері:',
                text: 'Бөлшек дүкендер туралы ақпарат мына жерде тұр',
            },
            chapter_02: {
                title: '"АДИДАС" ЖШС',
                text: 'Алматы, Медеу ауданы, Достық даңғылы, 210, кеңсе А-3 Блок',
            },
        }
    },

    sidebar: {
        help: {
            title: 'Көмек қажет пе?',
            delivery: 'Жеткізу',
            return: 'қайтару',
            contacts: 'Байланыс деректері',
        },
        pay: {
            title: 'Төлем әдістері'
        }
    },

    empty: {
        title: 'Себет бос',
        text: 'Мұнда себетке салынған тауарлар шығады. Жаңа сауданы бастаймыз ба?',
        toStore: 'Зат сатып алуға',
        tooltip: 'Себетте тауар жоқ'
    },

    content: {
        total: 'Барлығы',
        size: 'Өлшем',
        sizeEnded: 'Бұл тауар таяуда таусылады',
        sizeEnd: 'Қолда жоқ',
        count: 'Саны',
        yourOrder: 'Сенің тапсырысың',
        firstPrice: 'Бастапқы баға',
        sale: 'Жеңілдік',
        withVat: 'ҚҚС қосқанда',
        promocode: 'Промо-код',
        noIdentify: 'сәйкестендірілмеген. Промо-код дұрыс па, тексер, және тағы байқап көр.',
        apply: 'Қолданып көру',
        code: 'Код',
        applied: 'Қолданылды',
        delivery: 'Жеткізу',
        enterPromoCode: 'Промо-код енгіз',
        maxCountTitle: 'Тапсырысыңды өзгертуіңді сұраймыз ',
        maxCountText: 'Себеттегі тауар саны ең көп 10 мәнінен асады.',
    },

    notification: {
        updateCartTitle: {
            title: 'Себетке салынған тауарлар саны жаңартылды.',
            text: ' {name} таңдаулыға қосылды.'
        }
    }
}
