export default {
    subscribePopup: {
        title: "Өлшемнің бар-жоғы туралы хабарлама алу үшін өз электрондық мекенжайыңды раста",
        description: "Өлшемнің бар-жоғы туралы хабарлама алу үшін өз электрондық мекенжайыңды раста. Егер ол расталып қойған болса, онда email қалдыр, ал біз сенің өлшемің қолымызға тигенде саған хат жібереміз.",
        sizeTitle: "Өлшем",
        agreementText: "Мен 16 жасқа толғанымды растаймын",
        submitBtnText: "Қол қою",
        footerInfo1: "Жоғарыда «Қол қою» түймесін басып, «АДИДАС» ЖШС-ға",
        footerInfoLinkUrl1: " https://media.adidas.kz/personal_data_accept_kz.pdf",
        footerInfoLinkText1: "дербес деректерді өңдеуге және ақпараттық таратпа алуға келісімімді беремін",
        footerInfo2: "және мынаған",
        footerInfoLinkText2: "Дербес деректерді өңдеу жөніндегі саясатпен келісемін",
        footerInfoLinkUrl2: "https://media.adidas.kz/personal_data_policy_kz.pdf",
    },
    successEmailSubscribe: {
        title: "Дайын! Өлшемің сайтта пайда болғанда email аласың",
        description: "",
        btnText: "Сатып алуды жалғастыр",
    },
    needConfirmEmailSubscribe: {
        title: "Электрондық мекенжайды растауды күтеміз",
        description: "Растау хаты сен көрсеткен электрондық пошта мекенжайына жіберілді.",
        btnText: "Сатып алуды жалғастыру",
    },
    errorEmailSubscribe: {
        title: "Қате орын алды",
        description: "Функционалды кейінірек қолданып көріңіз",
        btnText: "Сатып алуды жалғастыру",
    },
};
