export default {
    subscribePopup: {
        title: "Подтверди электронный адрес, чтобы получить уведомление о наличии размера",
        description: "Подтверди свой электронный адрес, чтобы получать уведомление о наличии размера. Если он уже подтвержден, то оставляй email, а мы отправим тебе письмо, как только твой размер появится в наличии.",
        sizeTitle: "Размер",
        agreementText: "Я подтверждаю, что мне уже исполнилось 16 лет",
        submitBtnText: "Подписаться",
        footerInfo1: "Нажимая кнопку выше «Подписаться», я даю ТОО «АДИДАС»",
        footerInfoLinkUrl1: "https://media.adidas.kz/personal_data_accept.pdf",
        footerInfoLinkText1: "свое согласие на обработку персональных данных и получение информационных рассылок",
        footerInfo2: "и соглашаюсь с",
        footerInfoLinkText2: "Политикой по обработке персональных данных",
        footerInfoLinkUrl2: "https://media.adidas.kz/personal_data_policy.pdf",
    },
    successEmailSubscribe: {
        title: "Готово! Ты получишь email, когда твой размер появится на сайте",
        description: "",
        btnText: "Продолжить покупки",
    },
    needConfirmEmailSubscribe: {
        title: "Ждем подтверждение электронного адреса",
        description: "Письмо с подтверждением было отправлено на указанный тобой адрес электронной почты.",
        btnText: "Продолжить покупки",
    },
    errorEmailSubscribe: {
        title: "Произошла ошибка",
        description: "Попробуйте воспользоваться функционалом позже",
        btnText: "Продолжить покупки",
    },
};
